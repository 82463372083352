import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import moment from "moment";

import { Menu, Rate } from "antd";
import configs from '../../../../../config/urlConfigs.js';
import { generatePreSignedGetUrl } from '../../../../../s3PresignedUrls.js';
import { Rating } from 'react-simple-star-rating';
const readCookie = require('../../../../../cookie.js').readCookie;

export default class SingleOpportunityFeedbackModal extends Component {
  constructor(props) {
    super(props)
   
    this.state = {
        participants:props.participants,
        index : props.index,
        feedback:[],
        selectedOpportunity:props.data.selectedOpportunity
    
    }
    this.getTimeDiff = this.getTimeDiff.bind(this);
  }

 
componentDidMount(){
  // let path = this.props.path;
  //   fetch(
  //   configs.BACKEND_API_URL +
  //   "/ee/volunteer-opportunity/" +
  //   path +
  //   "/feedback",
  //   {
  //     method: "GET",
  //     headers: {
  //       Auth: JSON.parse(readCookie("access_token")).access_token,
  //     },
  //   }
  // )
  //   .then((data) => data.json())
  //   .then(
  //     function (data) {
                                                                         
  //       this.setState( { feedback: data.data });
  //     }.bind(this)
  //   );
}
  
  getTimeDiff(startTime, endTime) {
    var time = "";
    var hours = parseInt(
      moment
        .duration(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm")))
        .asHours()
    );
    if (hours > 1) time = hours + "  hours ";
    else if (hours === 1) time = hours + "  hour ";
    var mins = parseInt(
      moment
        .duration(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm")))
        .asMinutes() % 60
    );
    if (mins > 1) time += mins + " minutes ";
    else if (mins === 1) time += mins + " minute ";
    if (time === "") return false;
    return time;
  }
 

  render() {
    return (
      
      
      <Modal
        bsSize="large"
        className="declare-modal"
        show={this.props.showDeclareModalVisibility}
        onHide={this.props.hideDeclareModal}
      >
       
       
        <Modal.Header closeButton>
          <Modal.Title className="modal-header-custom">
            Feedback
          </Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <Row>
            <Col className="margin-col-50" md={12}>


            
                                  <ul>
                                    <li >
                                      <Row>
                                        <Col md={12}>
                                          <Row className="flex">
                                            <Col className="comment" md={12}>
                                              <div className="just-flex">
                                                <img
                                                  src={
                                                    this.state.participants[this.state.index].profilePicture
                                                      ?  generatePreSignedGetUrl(this.state.participants[this.state.index].profilePicture)
                                                      : "/img/user.png"
                                                  }
                                                  style={{
                                                    width: 50,
                                                    height: 50,
                                                    background: "#e0e0e0",
                                                    padding: 3,
                                                  }}
                                                  alt=""
                                                  className="img-circle img-responsive mr10"
                                                />
                                                {/* <Row><Col md={12}></Col> </Row>  */}
                                                <Row>
                                                  <Row>
                                                    <Col
                                                      md={12}
                                                      className="feedback-name"
                                                    >
                                                      { this.state.participants[this.state.index].fullName}
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col
                                                      md={12}
                                                      className="feedback-time"
                                                    >
                                                      Duration: {" "} 
                                                      {this.getTimeDiff(
                                                        this.state.participants[this.state.index]?.updatedStartTime ||   this.state.participants[this.state.index]?.startTime || this.state.selectedOpportunity?.startTime,
                                                         this.state.participants[this.state.index]?.updatedEndTime || this.state.participants[this.state.index]?.endTime || this.state.selectedOpportunity?.endTime
                                                      )}{" "}
                                                      | Experience submitted on:{" "}
                                                      { this.state.participants[this.state.index].hasOwnProperty(
                                                        "createdOn"
                                                      ) ? (
                                                        <span className="writer-date">
                                                          {moment(
                                                             this.state.participants[this.state.index].createdOn
                                                          ).format(
                                                            "DD/MM/YYYY h:mm a"
                                                          )}
                                                        </span>
                                                      ) : null}
                                                    </Col>
                                                  </Row>{" "}
                                                </Row>
                                              </div>
                                              <div className="feedback-rating">
                                                <Col md={12}>
                                                  <Col md={3}>
                                                    <Row>
                                                      {" "}
                                                      <Col md={12}>
                                                        {/* <Rate
                                                          className="feedback-star-ratings"
                                                          // style={{fontSize:8,color:"#EF5A20"}}
                                                          defaultValue={
                                                            this.state.participants[this.state.index].questionSelectedOption1
                                                          }
                                                          disabled
                                                        /> */}
                                                        <Rating 
                                                          allowHover={false}
                                                          disableFillHover={false}
                                                          size={12}
                                                          fillColor={configs.THEME_COLOR}
                                                          initialValue={this.state.participants[this.state.index].questionSelectedOption1} 
                                                          readonly={true}
                                                          />
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col md={12} className="feedback-tag" >
                                                        Impact
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                  <Col md={3}>
                                                    <Row>
                                                      <Col md={12}>
                                                        {/* <Rate
                                                          // style={{fontSize:8 ,color:"#EF5A20"}}
                                                          className="feedback-star-ratings"
                                                          defaultValue={
                                                            this.state.participants[this.state.index].questionSelectedOption2
                                                          }
                                                          disabled
                                                        /> */}
                                                        <Rating 
                                                          allowHover={false}
                                                          disableFillHover={false}
                                                          size={12}
                                                          fillColor={configs.THEME_COLOR}
                                                          initialValue={this.state.participants[this.state.index].questionSelectedOption2} 
                                                          readonly={true}
                                                          />
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col md={12}
                                                        className="feedback-tag"
                                                      >
                                                        Satisfaction
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                  <Col md={6}>
                                                    <Row>
                                                      <Col md={12}>
                                                        {/* <Rate
                                                          // style={{fontSize:8 ,color:"#EF5A20"}}
                                                          className="feedback-star-ratings"
                                                          defaultValue={
                                                            this.state.participants[this.state.index].questionSelectedOption3
                                                          }
                                                          disabled
                                                        /> */}
                                                        <Rating 
                                                          allowHover={false}
                                                          disableFillHover={false}
                                                          size={12}
                                                          fillColor={configs.THEME_COLOR}
                                                          initialValue={this.state.participants[this.state.index].questionSelectedOption3} 
                                                          readonly={true}
                                                          />
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col
                                                        md={12}
                                                        className="feedback-tag"
                                                      >
                                                        Likelihood of
                                                        participation again
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Col>
                                              </div>
                                             {this.props.selectedOpportunityData?.addUnitOfMeasurement=='Yes' &&<>    
                                               <div className="feedback-experience">
                                                <p>{this.props.selectedOpportunityData.unitOfMeasurement}</p>
                                                <div>{ this.props.selectedOpportunityData.attendeeDeclaration?.unitOfMeasurementanswer}</div>
                                              </div>
                                             </>}                                              <div style={{marginTop:'4%'}} className="feedback-experience">
                                                <p>Experience</p>
                                                <div>{ this.state.participants[this.state.index].experience}</div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </li>
                                  </ul>
                                </Col>



        
          </Row>
        </Modal.Body>
      
      </Modal>
    )
  }
}
























